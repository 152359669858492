@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@200;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
* {
  padding: 0;
  margin: 0;
  background-color: #262936;
  font-family: Poppins, sans-serif;
  color: white;
  text-decoration: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.title {
  font-size: 1.5rem;
  color: #fd2a36;
  font-weight: 400;
  font-style: normal;
}

p {
  font-weight: 400;
  font-size: 1.125rem;
  font-style: normal;
}
